import React, {useEffect, useState} from "react";
import "./style.scss";
import {
	Modal,
	Grid,
	Input,
	Dropdown,
	Button,
	Divider,
	Header
} from "semantic-ui-react";
import { useForm, Controller, ErrorMessage } from "react-hook-form";
import countriesList from "./countries.json";
import jobRolesList from "./jobRoles.json";
import jobFunctionsList from "./jobFunctions.json";
import axios from 'axios';
import { getBaseUrl, getRequestHeaders} from './../../../lib/helpers';

function TakeTestForm(props) {

	const { control, handleSubmit, register,  setValue, setError, clearError, errors  } = useForm();

	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isValidating, setIsValidating] = useState(false);
	
	// https://mirqah.kanbanize.com/ctrl_board/8/cards/737/details/
	// manually register country Code because of error validation 
	// not clearing after selection when used within controller
	useEffect(() => {
		register({ name: "countryCode" }, {required : "Required"});
		register({ name: "jobRole" }, {required : "Required"});
		register({ name: "jobFunction" }, {required : "Required"});
	  }, [register]);
	
	const handleOnDropDownChange = (fieldName, event, dropdown) => {
		if(dropdown.value != null && dropdown.value.trim() !== '') {
			setValue(fieldName, dropdown.value);
			clearError(fieldName);
		} else {
			setError(fieldName, "required", "Required");
		}
	};

	const handleCountryCodeDropDownChange = handleOnDropDownChange.bind(this, "countryCode");
	const handleJobRoleDropDownChange = handleOnDropDownChange.bind(this, "jobRole");
	const handleJobFunctionDropDownChange = handleOnDropDownChange.bind(this, "jobFunction");

	const onSubmit = data => {
		setIsSubmitting(true);
		props.submit(data);
	};

	const validateInvitationCode = async (value) => {
		let isValid = false;
		setIsValidating(true);
		await axios.get(`${getBaseUrl()}/score_card/invitation_code/${value}`, getRequestHeaders())
					.then((res) => isValid = res && res.status === 200 && res.data.id != null)
					.catch(error => isValid = false)
					.finally(() => setIsValidating(false));
		return isValid || 'Invalid Invitation Code';
	}

	const nameValidationRules = { 
		required: "Required",
		minLength: { value: 2, message: "Name length can't be less than 2 characters" },
		maxLength: { value: 20, message: "Name length can't be more than 20 characters" },
		pattern: {
			value: /^[a-zA-ZÀ-ÿ-_]+( +[a-zA-ZÀ-ÿ-_]+)*$/i,
			message: "Name must be charaters with space, '-', or '_' between words"
		}
	};

	useEffect(() => {
		if(props.hasSentInvitationCode === true) {
			setValue("invitationCode", props.sentInvitationCode);
		}
	  }, [props.sentInvitationCode]);

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Grid centered verticalAlign="middle">
				<Divider hidden />
					<Grid.Row className="pb-0">
						<Grid.Column mobile={14} tablet={14} computer={12} >
							<p className="survey-info">
								This survey is meant as an easy and quick way to get a sense of your areas of
								strengths and potential areas of development, as well as a personal and engaging
								way to learn about key areas of management. It is not meant as a formal evaluation tool.
							</p>
							<p className="survey-info">You will receive a personal report with your results by mail. Your data shall remain confidential and anonymous.</p>
							<p className="survey-info" >It should take you around 15 minutes.</p>

						</Grid.Column>
					</Grid.Row>
					
					<Grid.Row className="pb-0">
						<Grid.Column mobile={7} tablet={7} computer={6}>
							<Controller as={Input} name="firstName"
								fluid size="large" placeholder="First Name" 
								control={control} rules={nameValidationRules}
								defaultValue = ""
								error={errors.firstName ? true : false} />
						</Grid.Column>
						<Grid.Column mobile={7} tablet={7} computer={6}>
							<Controller as={Input} name="lastName"
								fluid size="large" placeholder="Last Name"
								control={control} rules={nameValidationRules}
								defaultValue = ""
								error={errors.lastName ? true : false} />
						</Grid.Column>
					</Grid.Row>
					<Grid.Row className="pt-1 pb-0"> 
						<Grid.Column mobile={7} tablet={7} computer={6}>
							<ErrorMessage errors={errors} name="firstName" as="p" className="error" />
						</Grid.Column>
						<Grid.Column mobile={7} tablet={7} computer={6}>
							<ErrorMessage errors={errors} name="lastName" as="p" className="error" />
						</Grid.Column>
					</Grid.Row>
					
					<Grid.Row className="pb-0">
						<Grid.Column mobile={14} tablet={7} computer={6} className="mb-sm-3">
							<Controller as={Input} name="email" 
								fluid size="large" placeholder="Email"
								control={control} rules={{ required: "Required", 
									pattern: {
									  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
									  message: "Invalid email address"
									} }}
								defaultValue = ""
								error={errors.email ? true : false} />
							<ErrorMessage errors={errors} name="email" as="p" className="error d-none d-sm-block mt-sm-3" />
						</Grid.Column>
						<Grid.Column mobile={14} tablet={7} computer={6} className="mb-sm-3">
							<Controller as={Input} name="phoneNumber"
								fluid size="large" placeholder="Phone Number"
								control={control} rules={{ required: "Required",
									maxLength: { value: 25, message: "Phone number max length is 25 digits" },
									minLength: { value: 5, message: "Phone number min length is 5 digits" },
									pattern: {
										value: /^[-0-9()+]+( +[0-9()-]+)*$/i,
										message: "Invalid phone number."
									} }}
								defaultValue = ""
								error={errors.phoneNumber ? true : false} />
						</Grid.Column>
					</Grid.Row>
					<Grid.Row className="pt-1 pb-0"> 
						<Grid.Column mobile={14} tablet={7} computer={6}>
							<ErrorMessage errors={errors} name="email" as="p" className="error d-sm-none" />
						</Grid.Column>
						<Grid.Column mobile={14} tablet={7} computer={6}>
							<ErrorMessage errors={errors} name="phoneNumber" as="p" className="error" />
						</Grid.Column>
					</Grid.Row>
					
					<Grid.Row className="pb-0">
						<Grid.Column mobile={14} tablet={7} computer={6} className="mb-sm-3">
							<Controller as={Input} name="companyName"
								fluid size="large" placeholder="Company"
								control={control} rules={{ required: "Required",
									minLength: { value: 2, message: "Company name length can't be less than 2 characters" },
									maxLength: { value: 50, message: "Invitation code length can't be more than 50 characters" },
									pattern: {
									value: /.*[^ ].*/,
									message: "Company name can't be Empty"
									} }}
								defaultValue = ""
								error={errors.companyName ? true : false} />
							<ErrorMessage errors={errors} name="companyName" as="p" className="error d-none d-sm-block mt-sm-3" />
						</Grid.Column>
						<Grid.Column mobile={14} tablet={7} computer={6} className="mb-sm-3">
							<Dropdown name="countryCode" onChange={handleCountryCodeDropDownChange}
									fluid placeholder="Select Country"
									search selection options={countriesList}
									error={errors.countryCode ? true : false} />
						</Grid.Column>
					</Grid.Row>
					<Grid.Row className="pt-1 pb-0"> 
						<Grid.Column mobile={14} tablet={7} computer={6}>
							<ErrorMessage errors={errors} name="companyName" as="p" className="error d-sm-none" />
						</Grid.Column>
						<Grid.Column mobile={14} tablet={7} computer={6}>
							<ErrorMessage errors={errors} name="countryCode" as="p" className="error" />
						</Grid.Column>
					</Grid.Row>
					
					<Grid.Row className="pb-0">
						<Grid.Column mobile={14} tablet={7} computer={6} className="mb-sm-3">
							<Dropdown name="jobRole" onChange={handleJobRoleDropDownChange}
									fluid placeholder="Job Role"
									search selection options={jobRolesList}
									error={errors.jobRole ? true : false} />
						</Grid.Column>
						<Grid.Column mobile={14} tablet={7} computer={6} className="mb-sm-3">
							<Dropdown name="jobFunction" onChange={handleJobFunctionDropDownChange}
									fluid placeholder="Function"
									search selection options={jobFunctionsList}
									error={errors.jobFunction ? true : false} />
						</Grid.Column>
					</Grid.Row>
					<Grid.Row className="pt-1 pb-0"> 
						<Grid.Column mobile={14} tablet={7} computer={6}>
							<ErrorMessage errors={errors} name="jobRole" as="p" className="error d-sm-none" />
						</Grid.Column>
						<Grid.Column mobile={14} tablet={7} computer={6}>
							<ErrorMessage errors={errors} name="jobFunction" as="p" className="error" />
						</Grid.Column>
					</Grid.Row>
					
					<Grid.Row className="pb-0">
						<Grid.Column mobile={14} tablet={14} computer={12}>
							<Controller as={Input} name="invitationCode"
								fluid size="large" placeholder="Invitation Code"
								control={control} 
								disabled={props.hasSentInvitationCode}
								rules={{ required: "Required",
									maxLength: { value: 20, message: "Invitation code length can't be more than 20 characters" },
									validate: validateInvitationCode,
									}}
								defaultValue = ""
								error={errors.invitationCode ? true : false} />
							<ErrorMessage errors={errors} name="invitationCode" as="p" className="error mt-sm-3" />
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column mobile={14} tablet={14} computer={16} textAlign="center" >
							<Button circular color="red" size="huge" className={`redBtn mt-sm-3 ${isSubmitting || isValidating ? 'disabled loading' : ''}`}
								type="submit">
								Discover your score now
							</Button>
						</Grid.Column>
					</Grid.Row>
			</Grid>
		</form>
	);
}


class TakeTestModal extends React.Component {

	state = {
		sentInvitationCode: null,
		hasSentInvitationCode: false
	};

	componentDidMount() {	
		const sentInvitationCode = new URLSearchParams(window.location.search).get("invitation_code");
		const hasSentInvitationCode = sentInvitationCode !== undefined  && sentInvitationCode !== null && sentInvitationCode.trim() !== '';
		this.setState( { sentInvitationCode, hasSentInvitationCode } );
	}

	render() {
		return (
			<Modal closeIcon id="character_power" size="small" centered className="power--modal"
				open={this.props.visible} onClose={() => this.props.closeActiveModal()} >
				<Header as="h2" textAlign="center">
					Take the Management Scorecard
        		</Header>
				<TakeTestForm submit= {this.props.submitTestTaker} sentInvitationCode={this.state.sentInvitationCode} hasSentInvitationCode={this.state.hasSentInvitationCode} />
				<Divider hidden />
			</Modal>
		);
	}
}
export default TakeTestModal;
