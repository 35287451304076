import React from "react";
import "./style.scss";
import { Image, Menu } from "semantic-ui-react";
import * as Scroll from 'react-scroll';
import { Link } from "react-router-dom";
class MainHeader extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  static defaultProps = {
    showNavLinks: true,
  };


  render() {
    const logoImage = this.props.darkLogo
      ? require("./../../assets/navbar/logo_dark_name.png")
      : require("./../../assets/navbar/logo.png");
    const mobile = this.state.width >= 767 ? false: true;
    
    return (
      <Menu className="main-menu" inverted secondary size={mobile? 'small' : 'large'} borderless>
        <Menu.Menu position="left">
          <Menu.Item as={Link} to={"//mirqah.com"} target="_blank">
            <Image inline spaced src={logoImage} />
          </Menu.Item>
        </Menu.Menu>

        {this.props.showNavLinks && (
          <Menu.Menu position="right">
            <Menu.Item
              className="white-text header-text"
              as={Scroll.Link}
              onClick={() => Scroll.scroller.scrollTo('howItWorks', { smooth: true, duration: 750 })}
            >
              How It Works?
            </Menu.Item>
            <Menu.Item
              className="white-text header-text"
              as={Scroll.Link}
              onClick={() => Scroll.scroller.scrollTo('aboutUs', { smooth: true, duration: 750 })}
            >
              About
            </Menu.Item>
            <Menu.Item
              className="white-text header-text"
              as={Link}
              to={"/"}
              onClick={this.props.contactUsHandler}
            >
              Contact
            </Menu.Item>
          </Menu.Menu>
        )}
      </Menu>
    );
  }
}
export default MainHeader;
