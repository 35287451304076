import React from "react";
import "./style.scss";
import { withRouter, Link } from "react-router-dom";
import { compose } from "react-compose";
import {
  Container,
  Grid,
  Header,
  Divider,
  Button,
  Progress,
  Image,
  Dimmer,
  Loader,
  Segment,
  Menu,
  List,
} from "semantic-ui-react";
import { get, getLocalStore, getBaseUrl } from "./../../lib/helpers";
import axios from "axios";
import ReactPixel from 'react-facebook-pixel';

function QuestionPageHeader(props) {
  return (
    <Menu
      inverted
      secondary
      size="large"
      borderless
      className={props.className}
    >
      <Menu.Menu position="left">
        <Menu.Item>
          <Image inline spaced src={require("../../assets/footer/logo.png")} />
        </Menu.Item>
      </Menu.Menu>
    </Menu>
  );
}

class Questions extends React.Component {
  state = {
    score_card_id: null,
    test_taker_id: null,
    invite_code: null,
    invitation_code: null,
    questions: [],
    currentQuestion: null,
    test_answers_attributes: [],
    submittingAnswersInProgress: false,
  };

  componentDidMount() {
    if (this._canComponentMount()) {
      this.setState(this._getTestInfo());

      axios
        .get(this._getScoreCardQuestionsUrl(), {
          headers: { Authorization: getLocalStore("client_token") },
        })
        .then((res) => {
          res.data.sort((f, s) => f.id - s.id);
          res.data.length > 0
            ? this.setState({
              questions: res.data,
              currentQuestion: res.data[0],
            })
            : console.log("no questions found");
        });
    } else {
      throw new Error("Invalid state to load component");
    }
  }

  submitAnswer = (arr_index, question_id, answer_id) => {
    const questions = this.state.questions;
    let test_answers_attributes = this.state.test_answers_attributes;
    const obj = { question_id, answer_id };
    test_answers_attributes.push(obj);

    this.setState({ test_answers_attributes }, () => {
      if (this._isSubmitionInProgress()) {
        console.warn("Another submission already in progress");
        return;
      }

      if (question_id === questions[questions.length - 1].id) {
        this._submitionInProgress();
        this._submitAnswersToServer();
      }
    });

    if (arr_index < questions.length - 1) {
      this.setState({ currentQuestion: questions[arr_index + 1] });
    }
  };

  render() {
    return (
      <Container fluid className="min-height">
        <Dimmer active={this.state.submittingAnswersInProgress} page>
          <Loader inverted indeterminate size="huge">
            Generating your result report ...{" "}
          </Loader>
        </Dimmer>
        <QuestionPageHeader className="my-0" />
        <Grid centered verticalAlign='middle'>
            <Grid.Row>
              <Grid.Column textAlign='center' mobile={16} tablet={16} computer={10}>
                <Image spaced src={require('./../../assets/questions.svg')} className="w-sm-60 w-pr-40" />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        {
          this.state.currentQuestion &&
          this.state.questions.map((question, i) => (
            question.id === this.state.currentQuestion.id &&
            <Grid centered verticalAlign='middle' key={question.id} className="question-answer-grid">

              <Grid.Row>
                <Grid.Column textAlign='center' mobile={16} tablet={14} computer={12}>
                  <Segment basic className='question-area'>
                    <Header as='p' size='large'>
                      {question.body}
                    </Header>
                  </Segment>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column mobile={16} tablet={14} computer={10}>
                  <p className="text-right">{`${i+1} / ${this.state.questions.length}`}</p>
                  <Progress size='tiny' percent={(i+1)*100/(this.state.questions.length)} error>
                  </Progress>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row className="answers-area">
                    {
                      question.answers.sort((f,s) => f.id - s.id).map( (answer) => (
                        <Button basic circular color='blue' size='big' onClick={ () => this.submitAnswer(i, question.id, answer.id) } className="capitalize mt-sm-2" key={answer.id}>
                          {answer.body}
                        </Button>
                      ))
                    }
              </Grid.Row>
            </Grid>
          ))
        }
      </Container>
    );
  }

  _canComponentMount() {
    return get(this.props, "history.location.state.test_taker_id");
  }

  _getFromHistory(propName) {
    return this.props.history.location.state[propName];
  }

  _getTestTakerId() {
    return JSON.parse(this._getFromHistory("test_taker_id"));
  }

  _getScoreCardId() {
    return JSON.parse(this._getFromHistory("score_card_id"));
  }

  _getGeneratedInviteCode() {
    return this._getFromHistory("invite_code").toString();
  }

  _getScorecardInvitationCode() {
    return this._getFromHistory("invitation_code").toString();
  }

  _getTestInfo() {
    const test_taker_id = this._getTestTakerId();
    const score_card_id = this._getScoreCardId();
    const invite_code = this._getGeneratedInviteCode();
    const invitation_code = this._getScorecardInvitationCode();
    return { test_taker_id, score_card_id, invite_code, invitation_code };
  }

  _getScoreCardQuestionsUrl() {
    return `${getBaseUrl()}/score_cards/${this._getScoreCardId()}/questions`;
  }

  _isSubmitionInProgress() {
    return this.state.submittingAnswersInProgress;
  }

  _submitionInProgress(isInProgress) {
    this.setState({
      submittingAnswersInProgress:
        isInProgress === undefined ? true : isInProgress,
    });
  }

  _submitAnswersToServer() {
    ReactPixel.track('SubmitApplication');
    const test_taker_id = this.state.test_taker_id;
    const test_answers_attributes = this.state.test_answers_attributes;
    const invitation_code = this.state.invitation_code;
    const test_result = {
      test_taker_id,
      test_answers_attributes,
      invitation_code,
    };
    axios
      .post(
        `${getBaseUrl()}/score_cards/${this.state.score_card_id}/test_results`,
        { test_result },
        {
          headers: { Authorization: "Bearer " + getLocalStore("client_token") },
        }
      )
      .then((response) => {
        if (response.status === 201) {
          let redirectToResults = "/result/";
          this.props.history.push({
            pathname: redirectToResults,
            state: {
              result: JSON.stringify(response.data),
              invite_code: JSON.stringify(this.state.invite_code),
            },
          });
        }
      })
      .finally(() => {
        this._submitionInProgress(false);
      });
  }
}

export default compose(withRouter)(Questions);
