import React from "react";
import "./style.scss";
import {
  Grid,
  List,
  Flag,
  Icon,
  Image,
  Divider,
  Container,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import logo from "../../assets/footer/logo.png";
import facebook from "../../assets/footer/fb.png";
import linkedin from "../../assets/footer/in.png";
import twitter from "../../assets/footer/twitter.png";
class Footer extends React.Component {
  render() {
    return (
      <Container fluid className="footer-down">
        <Grid centered verticalAlign="middle">
          <Grid.Row>
            <Grid.Column mobile={15} tablet={8} computer={7}>
              <List>
                <List.Item as="small">
                  <Image src={logo} />
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column mobile={1} tablet={1} computer={1}></Grid.Column>
            <Grid.Column mobile={1} tablet={1} computer={1}></Grid.Column>
            <Grid.Column mobile={16} tablet={3} computer={4} textAlign="center">
              <List horizontal>
                <List.Item
                  as={Link}
                  to={"//facebook.com/Mirqah/"}
                  target="_blank"
                >
                  <Image src={facebook} inline />
                </List.Item>
                <List.Item
                  as={Link}
                  to={"//twitter.com/mirqah"}
                  target="_blank"
                >
                  <Image src={twitter} inline />
                </List.Item>
                <List.Item
                  as={Link}
                  to={"//linkedin.com/company/mirqah"}
                  target="_blank"
                >
                  <Image src={linkedin} inline />
                </List.Item>
              </List>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <ToastContainer position="top-right" />
      </Container>
    );
  }
}
export default Footer;
