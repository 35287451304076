import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css'
import './app.scss';
import { getFbPixelId, getGTMConfig } from './lib/helpers'
import ReactPixel from 'react-facebook-pixel';
import TagManager from 'react-gtm-module';

import Home from './containers/home';
import Questions from './containers/questions';
import Result from './containers/result';


const options = {
  autoConfig: true, 	
  debug: false, 		
};

ReactPixel.init(getFbPixelId(), options);

const gtmConfig = getGTMConfig();
TagManager.initialize({
  gtmId: gtmConfig.id,
  auth: gtmConfig.auth,
  preview: gtmConfig.preview
});

function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route exact path='/' component={Home} />
          <Route path='/questions' component={Questions} />
          <Route path='/result' component={Result} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
