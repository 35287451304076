import React from "react";
import "./style.scss";
import { withRouter } from "react-router-dom";
import { Element } from 'react-scroll';
import { compose } from "react-compose";
import axios from "axios";
import {
  Container,
  Grid,
  Header,
  Segment,
  Divider,
  Button,
  Image,
  Icon,
} from "semantic-ui-react";
import TakeTestModal from "./take-test-modal";
import ContactUs from "./contact-us-modal";
import Footer from "../../components/footer";
import MainHeader from "../../components/main-header";
import {
  setLocalStore,
  getBaseUrl,
  getCredintials,
  getRequestHeaders,
} from "./../../lib/helpers";
import ReactPixel from 'react-facebook-pixel';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      contactUsVisible: false,
      scoreCards: [],
      score_card_id: null,
    };
  }
  componentDidMount() {
    ReactPixel.pageView();
    axios.post(`${getBaseUrl()}/auth/login`, getCredintials()).then((res) => {
      const token = res.data.auth_token;
      setLocalStore("client_token", token);
    });
  }

  contactUsHandler = () => this.setState({ contactUsVisible: true });

  submitContactUsSuccessful = () => {
    ReactPixel.track('Contact');
    this.setState({ contactUsVisible: false });
  };

  submitTestTaker = (formData) => {
    ReactPixel.track( "Lead"); 		
    let test_taker = this._mapFormDataToTestTaker(formData);
    this._getScoreCardByInvitationCode(test_taker.invited_code)
      .then((res) => {
        console.log(res);
        test_taker.score_card_id = res.data.id;
        return this._saveTestTaker(test_taker);
      })
      .then((res) => {
        const testTakerId = res.data.id;
        const testTakerGeneratedInviteCode = res.data.invite_code;
        let redirectToQuestions = "/questions/";
        this.props.history.push({
          pathname: redirectToQuestions,
          state: {
            test_taker_id: testTakerId,
            invite_code: testTakerGeneratedInviteCode,
            score_card_id: test_taker.score_card_id,
            invitation_code: test_taker.invited_code,
          },
        });
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  };

  render() {
    return (
      <Container fluid>
        <Grid className="aboveFold" centered>
          <Grid.Row verticalAlign="top">
            <Grid.Column mobile={16} tablet={16} computer={14}>
              <Divider hidden />
              <MainHeader
                contactUsHandler={this.contactUsHandler}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row verticalAlign="middle">
            <Grid.Column
              mobile={16}
              tablet={16}
              computer={16}
              textAlign="center"
            >
              <Header inverted as="h1" size="huge" className="above-header d-sm-none">
                Gauge your management ability
                <br />and enhance your management skills
              </Header>
              <Header inverted as="h1" size="huge" className="above-header d-sm-block">
                Gauge your management ability and enhance your management skills
              </Header>
              <p className="white-txt">
                Find out how well you perform in different aspects of
                management.
                <br />
                Evaluate your ability to create value to your employees,
                organization and community.
              </p>
              <Divider hidden section />
              <Button
                circular
                size="huge"
                color="red"
                className="redBtn"
                onClick={() => this.setState({ modalVisible: true })}
              >
                Take the Management Scorecard Now
              </Button>
            </Grid.Column>
          </Grid.Row>
          <Divider hidden />
        </Grid>

        <Grid centered verticalAlign="middle" className="benefits-grid">
          <Element name="howItWorks"> </Element>
          <Grid.Row>
            <Grid.Column
              mobile={16}
              tablet={16}
              computer={12}
              textAlign="center"
            >
              <Header as="h3" size="large">
                How It Works?
              </Header>
              <Header color="grey" as="h4" size="small">
                Mirqah’s Management Scorecard helps you to identify the
                strengths and gaps in your management practice. <br />
                You will receive a report with recommendations to develop your
                management skills.
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Divider hidden />
          <Grid.Row>
            <Grid.Column textAlign="center" mobile={16} tablet={5} computer={5}>
              <Segment basic>
                <Image centered src={require("./../../assets/01.png")} />
                <Header as="h4" color="red" size="small">
                  Take the Test
                </Header>
                <p>
                  Answer the questions covering different dimensions of
                  management.
                </p>
              </Segment>
            </Grid.Column>
            <Grid.Column textAlign="center" mobile={16} tablet={5} computer={5}>
              <Segment basic>
                <Image centered src={require("./../../assets/02.png")} />
                <Header as="h4" color="red" size="small">
                  Review your results
                </Header>
                <p>
                  The report provides you with a breakdown of your performance
                  across the 6 dimensions.
                </p>
              </Segment>
            </Grid.Column>
            <Grid.Column textAlign="center" mobile={16} tablet={5} computer={5}>
              <Segment basic>
                <Image centered src={require("./../../assets/03.png")} />
                <Header as="h4" color="red" size="small">
                  Plan your development
                </Header>
                <p>
                  Set action items to develop specific dimensions, leveraging
                  the resources provided in the report.
                </p>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider section hidden />
        <Grid centered>
          <Grid.Row>
            <Grid.Column
              mobile={16}
              tablet={16}
              computer={12}
              textAlign="center"
            >
              <Header as="h3" size="large">
                The scorecard will measure you against
              </Header>
              <Header color="grey" as="h4" size="small">
                The 6 dimensions of management according to Mirqah’s
                VALUE-ability framework
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="pb-sm-0">
            <Grid.Column mobile={15} tablet={7} computer={7} className="mb-sm-3">
              <Grid verticalAlign="middle" centered>
                <Grid.Row>
                  <Grid.Column
                    mobile={5}
                    tablet={5}
                    computer={4}
                    textAlign="right"
                  >
                    <Icon
                      name="users"
                      circular
                      size="huge"
                      disabled
                      className="x-big icon-grey-background no-box-shadow"
                    />
                  </Grid.Column>
                  <Grid.Column mobile={11} tablet={11} computer={12}>
                    <Header as="h4" size="small" color="red">
                      Employee
                    </Header>
                    <p>
                      Focused on managing your own team in terms of their
                      development, performance and wellbeing.
                    </p>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
            <Grid.Column mobile={15} tablet={7} computer={7} className="mb-sm-3">
              <Grid verticalAlign="middle" centered>
                <Grid.Row>
                  <Grid.Column
                    mobile={5}
                    tablet={5}
                    computer={4}
                    textAlign="right"
                  >
                    <Icon
                      name="building outline"
                      circular
                      size="huge"
                      disabled
                      className="x-big icon-grey-background no-box-shadow"
                    />
                  </Grid.Column>
                  <Grid.Column mobile={11} tablet={11} computer={12} className="mt-sm-3">
                    <Header as="h4" size="small" color="red">
                      Organization
                    </Header>
                    <p>
                      Gauges your efficacy in managing organisational phenomena
                      such as change, politics and culture.
                    </p>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="pb-sm-0">
            <Grid.Column mobile={15} tablet={7} computer={7} className="mb-sm-3">
              <Grid verticalAlign="middle" centered>
                <Grid.Row>
                  <Grid.Column
                    mobile={5}
                    tablet={5}
                    computer={4}
                    textAlign="right"
                  >
                    <Icon
                      name="handshake outline"
                      circular
                      size="huge"
                      disabled
                      className="x-big icon-grey-background no-box-shadow"
                    />
                  </Grid.Column>
                  <Grid.Column mobile={11} tablet={11} computer={12}>
                    <Header as="h4" size="small" color="red">
                      Customer
                    </Header>
                    <p>
                      Gauges your understanding of your customers’ needs and how
                      your department, unit or organization satisfies these
                      needs.
                    </p>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
            <Grid.Column mobile={15} tablet={7} computer={7} className="mb-sm-3">
              <Grid verticalAlign="middle" centered>
                <Grid.Row>
                  <Grid.Column
                    mobile={5}
                    tablet={5}
                    computer={4}
                    textAlign="right"
                  >
                    <Icon
                      name="desktop"
                      circular
                      size="huge"
                      disabled
                      className="x-big icon-grey-background no-box-shadow"
                    />
                  </Grid.Column>
                  <Grid.Column mobile={11} tablet={11} computer={12} className="mt-sm-3">
                    <Header as="h4" size="small" color="red">
                      Work
                    </Header>
                    <p>
                      Gauges your management of work flows and processes
                      ensuring a continuous and smooth flow of outputs.
                    </p>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column mobile={15} tablet={7} computer={7} className="mb-sm-3">
              <Grid verticalAlign="middle" centered>
                <Grid.Row>
                  <Grid.Column
                    mobile={5}
                    tablet={5}
                    computer={4}
                    textAlign="right"
                  >
                    <Icon
                      name="dollar sign"
                      circular
                      size="huge"
                      disabled
                      className="x-big icon-grey-background no-box-shadow"
                    />
                  </Grid.Column>
                  <Grid.Column mobile={11} tablet={11} computer={12}>
                    <Header as="h4" size="small" color="red">
                      Cash
                    </Header>
                    <p>
                      Gauges your financial savviness and ability to make the
                      right decisions that positively impact the organization’s
                      financial performance.
                    </p>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
            <Grid.Column mobile={15} tablet={7} computer={7} className="mb-sm-3">
              <Grid verticalAlign="middle" centered>
                <Grid.Row>
                  <Grid.Column
                    mobile={5}
                    tablet={5}
                    computer={4}
                    textAlign="right"
                  >
                    <Icon
                      name="chart line"
                      circular
                      size="huge"
                      disabled
                      className="x-big icon-grey-background no-box-shadow"
                    />
                  </Grid.Column>
                  <Grid.Column mobile={11} tablet={11} computer={12} className="mt-sm-3">
                    <Header as="h4" size="small" color="red">
                      Strategy
                    </Header>
                    <p>
                      Gauges your awareness, understanding, and application of
                      the organization’s strategy within the scope of your
                      authority.
                    </p>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column
              textAlign="center"
              mobile={16}
              tablet={16}
              computer={16}
            >
              <Divider hidden />
              <Button
                circular
                size="huge"
                color="red"
                className="redBtn"
                onClick={() => this.setState({ modalVisible: true })}
              >
                Take the Management Scorecard Now
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider section hidden />
        <Grid centered verticalAlign="middle" className="benefits-grid">
          <Element name="aboutUs"> </Element>
          <Grid.Row>
            <Grid.Column
              mobile={16}
              tablet={16}
              computer={12}
              textAlign="center"
            >
              <Header as="h3" size="large" className="about-us">
                About Us
              </Header>
              <p> Since its foundation in 2007 Mirqah has been working with
              businesses, educating and developing managers, through rich,
              scholarly and relevant learning experiences. Over the past
              decade, we’ve worked with a number of Fortune 500 companies
              helping their managers create empowering workplaces, deliver
              quality products and services, and run smooth and profitable
              operations.</p>


            </Grid.Column>
          </Grid.Row>
          <Divider hidden />
        </Grid>
        <TakeTestModal
          visible={this.state.modalVisible}
          closeActiveModal={() => this.setState({ modalVisible: false })}
          submitTestTaker={this.submitTestTaker}
          score_card_id={this.state.score_card_id}
        />
        <ContactUs
          visible={this.state.contactUsVisible}
          closeActiveModal={() => this.setState({ contactUsVisible: false })}
          submitSuccessful={this.submitContactUsSuccessful}
        />
        <Footer />
      </Container>
    );
  }

  _getScoreCardByInvitationCode(code) {
    return axios.get(
      `${getBaseUrl()}/score_card/invitation_code/${code}`,
      getRequestHeaders()
    );
  }

  _saveTestTaker(testTaker) {
    return axios.post(
      `${getBaseUrl()}/test_takers`,
      testTaker,
      getRequestHeaders()
    );
  }

  _mapFormDataToTestTaker(formData) {
    return {
      first_name: formData.firstName,
      last_name: formData.lastName,
      email: formData.email,
      phone_number: formData.phoneNumber,
      company_name: formData.companyName,
      country: formData.countryCode,
      job_role: formData.jobRole,
      job_function: formData.jobFunction,
      invited_code: formData.invitationCode,
      score_card_id: "",
    };
  }
}
export default compose(withRouter)(Home);
