import React from "react";
import RadarChart from "react-svg-radar-chart";
import "./styles/radarChart.scss";
// import 'react-svg-radar-chart/build/css/index.css'

class RadarChartWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      radarChartData: {},
      options: {
        dots: false,
        zoomDistance: 1.4,
        scales: 6,
        captionProps: () => ({
          className: "caption",
          textAnchor: "middle",
          fontFamily: "Rude",
          height: "14px",
          width: "93px",
          fontSize: 8,
          letterSpacing: "0.84px"
        }),
        dotProps: () => ({
          className: "dot",
          mouseEnter: (dot) => {
            console.log(dot);
          },
          mouseLeave: (dot) => {
            console.log(dot);
          },
        }),
      },
    };
  }

  componentDidMount() {
    
    this.setState({
      radarChartData: [
        {
          data: this.props.categorizedScores,
          meta: { color: "#ed313c" },
        },
      ],
      captions: this.props.captions,
    });
    this.setState({ loading: false });
  }

  render() {
    if (this.state.loading) return <div />;

    return (
      <RadarChart
        id={this.props.id}
        captions={this.state.captions}
        data={this.state.radarChartData}
        size={this.props.size}
        options={this.state.options}
      />
    );
  }
}

export default RadarChartWrapper;
