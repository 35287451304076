import React, {useState} from "react";
import "./style.scss";
import {
  Modal,
  Grid,
  Input,
  Button,
  Divider,
  Header,
  TextArea,
} from "semantic-ui-react";
import { useForm, Controller, ErrorMessage } from "react-hook-form";
import axios from "axios";
import { getBaseUrl } from "../../../lib/helpers";

function ContactUsForm(props) {
  const { control, handleSubmit, errors } = useForm();
  
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = (data) => {
    setIsSubmitting(true);
    axios
      .post(getBaseUrl() + "/contact_requests/create", data)
      .then((res) => {
        if (res && res.status === 200) props.submit();
        else console.log("Error");
      })
      .catch((err) => console.log(err));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid centered verticalAlign="middle">
        <Divider hidden />
        <Grid.Row className="pb-0">
          <Grid.Column mobile={7} tablet={7} computer={6}>
            <Controller
              as={Input}
              name="first_name"
              fluid
              size="large"
              placeholder="First Name"
              control={control}
              rules={{
                required: "Required",
                minLength: {
                  value: 2,
                  message: "Name length can't be less than 2 characters",
                },
                maxLength: {
                  value: 20,
                  message: "Name length can't be more than 20 characters",
                },
                pattern: {
                  value: /^[a-zA-Z]+$/i,
                  message: "Only characters are allowed",
                },
              }}
              defaultValue=""
              error={errors.first_name ? true : false}
            />
          </Grid.Column>
          <Grid.Column mobile={7} tablet={7} computer={6}>
            <Controller
              as={Input}
              name="last_name"
              fluid
              size="large"
              placeholder="Last Name"
              control={control}
              rules={{
                required: "Required",
                minLength: {
                  value: 2,
                  message: "Name length can't be less than 2 characters",
                },
                maxLength: {
                  value: 20,
                  message: "Name length can't be more than 20 characters",
                },
                pattern: {
                  value: /^[a-zA-Z]+$/i,
                  message: "Only characters are allowed",
                },
              }}
              defaultValue=""
              error={errors.last_name ? true : false}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="pt-1 pb-0">
          <Grid.Column mobile={7} tablet={7} computer={6}>
            <ErrorMessage errors={errors} name="first_name" as="p" className="error" />
          </Grid.Column>
          <Grid.Column mobile={7} tablet={7} computer={6}>
            <ErrorMessage errors={errors} name="last_name" as="p" className="error" />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="pb-0">
          <Grid.Column mobile={14} tablet={7} computer={6} className="mb-sm-3">
            <Controller
              as={Input}
              name="email"
              fluid
              size="large"
              placeholder="Email"
              control={control}
              rules={{
                required: "Required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "Invalid email address",
                },
              }}
              defaultValue=""
              error={errors.email ? true : false}
            />
            <ErrorMessage errors={errors} name="email" as="p" className="error d-none d-sm-block mt-sm-3" />
          </Grid.Column>
          <Grid.Column mobile={14} tablet={7} computer={6} className="mb-sm-3">
            <Controller
              as={Input}
              name="company_name"
              fluid
              size="large"
              placeholder="Company"
              control={control}
              rules={{
                required: "Required",
                minLength: {
                  value: 2,
                  message:
                    "Company name length can't be less than 2 characters",
                },
                maxLength: {
                  value: 50,
                  message:
                    "Company name length can't be more than 50 character",
                },
                pattern: {
                  value: /.*[^ ].*/,
                  message: "Company name can't be Empty",
                },
              }}
              defaultValue=""
              error={errors.company_name ? true : false}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="pt-1 pb-0">
          <Grid.Column mobile={14} tablet={7} computer={6}>
            <ErrorMessage errors={errors} name="email" as="p" className="error d-sm-none" />
          </Grid.Column>
          <Grid.Column mobile={14} tablet={7} computer={6}>
            <ErrorMessage errors={errors} name="company_name" as="p" className="error" />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="pb-0">
          <Grid.Column
            mobile={14}
            tablet={7}
            computer={12}
            className="w-75"
          >
            <Controller
              as={Input}
              name="subject"
              fluid
              size="large"
              placeholder="Subject"
              control={control}
              rules={{
                required: "Required",
                minLength: {
                  value: 2,
                  message: "Subject can't be less than 2 characters",
                },
                maxLength: {
                  value: 200,
                  message: "Subject can't be more than 200 characters",
                },
                pattern: {
                  value: /.*[^ ].*/,
                  message: "Subject can't be Empty",
                },
              }}
              defaultValue=""
              error={errors.subject ? true : false}
            />
            <ErrorMessage errors={errors} name="subject" as="p" className="error mt-sm-3" />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="pb-0">
          <Grid.Column
            mobile={14}
            tablet={7}
            computer={12}
            className="subject-field"
          >
            <Controller
              as={TextArea}
              name="message"
              className={errors.message ? "contact-us-text-area contact-us-error" : "contact-us-text-area"}
              fluid
              size="huge"
              placeholder="Message"
              control={control}
              rules={{
                required: "Required",
                minLength: {
                  value: 20,
                  message: "Message can't be less than 20 characters",
                },
                maxLength: {
                  value: 1000,
                  message: "Message can't be more than 1000 characters",
                },
                pattern: {
                  value: /.*[^ ].*/,
                  message: "Message can't be Empty",
                },
              }}
              defaultValue=""
              error={errors.message ? true : false}
            />
            <ErrorMessage
              errors={errors}
              name="message"
              as="p"
              className="error mt-sm-3"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column mobile={14} tablet={14} computer={16} textAlign="center">
            <Button
              circular
              color="red"
              size="large"
              className={`red-button-contact mt-sm-3 ${isSubmitting  ? 'disabled loading' : ''} `}
              type="submit"
            >
              Send
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </form>
  );
}

class ContactUsModal extends React.Component {
  render() {
    return (
      <Modal
        closeIcon
        id="character_power"
        size="small"
        centered
        className="power--modal"
        open={this.props.visible}
        onClose={() => this.props.closeActiveModal()}
      >
        <Header as="h2" textAlign="center">
          Contact Us
        </Header>
        <ContactUsForm submit={this.props.submitSuccessful} />
        <Divider hidden />
      </Modal>
    );
  }
}

export default ContactUsModal;
