import React from "react";
import { List } from 'semantic-ui-react';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import { getSiteUrl } from './../../lib/helpers';

function ShareResult(props) {
    const SHARE_URL = `${getSiteUrl()}?invitation_code=${props.invitationCode}`;
    const SHARE_TITLE = "Mirqah Managment scorecard";
    const SHARE_MSG = `I got my result for the Management scorecard. \nFind out yours using my invitation code: ${props.invitationCode}`;
    const SHARE_HASHTAGS = ['mirqah', 'managment', 'scorecard'];

	return (
		<List horizontal>
            <List.Item>
                <FacebookShareButton url={SHARE_URL} quote={SHARE_MSG} hashtag={'#'+SHARE_HASHTAGS[0]}>
                    <img alt="facebook" className="social-media-icon" src={require("../../assets/results/fb.png")} />
                </FacebookShareButton>
            </List.Item>
            {/* <List.Item>
                <LinkedinShareButton url={SHARE_URL} title={SHARE_TITLE} summary={SHARE_MSG}>
                    <img alt="linkedin" className="social-media-icon" src={require("../../assets/results/linkedin.png")} />
                </LinkedinShareButton>
            </List.Item> */}
            <List.Item>
                <TwitterShareButton url={SHARE_URL} hashtags={SHARE_HASHTAGS} title={SHARE_MSG} via="mirqah">
                    <img alt="twitter" className="social-media-icon" src={require("../../assets/results/twitter.png")} />
                </TwitterShareButton>
            </List.Item>
            <List.Item>
                <WhatsappShareButton url={SHARE_URL} title={SHARE_MSG}>
                    <img alt="whatsApp" className="social-media-icon" src={require("../../assets/results/whatsapp.png")} />
                </WhatsappShareButton>
            </List.Item>
        </List>
	);
}

export default ShareResult;