import React from "react";
import "./styles/style.scss";
import { withRouter } from "react-router-dom";
import { compose } from "react-compose";
import { Grid, Header, Segment, Container } from "semantic-ui-react";
import MainHeader from "../../components/main-header";
import {
  get,
  getBaseUrl,
  getCredintials,
  getRequestHeaders,
  setLocalStore,
} from "./../../lib/helpers";
import axios from "axios";
import RadarChartWrapper from "./radarChart";
import { RadialChart } from "./radialChart";
import ShareResult from './share-result';


class Result extends React.Component {
  fetchedResult = null;

  state = {
    scoreSummary: {},
    inviteCode: "",
    radarChartData: {},
    isStateLoaded: false,
  };

  async componentDidMount() {
    if (this._canComponentMount() === false) {
      throw new Error("Invalid state to load component");
    }
    if (this.state.isStateLoaded) {
      return;
    }

    let resultCodeQueryParam = new URLSearchParams(window.location.search).get(
      "code"
    );
    if (resultCodeQueryParam !== null) {
      try {
        // TODO: use authentication service and request token before running requests
        let authResponse = await axios.post(
          `${getBaseUrl()}/auth/login`,
          getCredintials()
        );
        setLocalStore("client_token", authResponse.data.auth_token);

        // TODO: Use service instead of loading data inside component
        let { data } = await axios.get(
          `${getBaseUrl()}/result/summary/${resultCodeQueryParam}`,
          getRequestHeaders()
        );
        this.fetchedResult = data;
      } catch (error) {
        console.error(error);
        throw new Error("Failed to fetch result");
      }
    }

    this.setState(this._getState());
    this.setState({ isStateLoaded: true });
  }

  _canComponentMount() {
    let doesHistoryStateHasResult =
      get(this.props, "history.location.state.result") != null;
    let doesUrlHasResultCode =
      new URLSearchParams(window.location.search).get("code") != null;
    return doesHistoryStateHasResult || doesUrlHasResultCode;
  }

  _getFromHistory(propName) {
    return this.props.history.location.state[propName];
  }

  _getData(propName) {
    let dataAsJson = null;
    if (this.fetchedResult != null) {
      dataAsJson = this.fetchedResult[propName];
    } else {
      dataAsJson = JSON.parse(this._getFromHistory(propName));
    }

    return dataAsJson;
  }

  _getScoreSummary() {
    const score_summary = this._getData("result").score_summary;

    if (
      score_summary.is_percentage == null ||
      score_summary.is_percentage == undefined ||
      score_summary.is_percentage === false
    ) {
      score_summary.score_per_category.forEach((category) => {
        category.score = Math.ceil(
          (category.score / category.max_possible_score) * 100
        );
      });
      score_summary.is_percentage = true;
    }

    return score_summary;
  }

  _getInviteCode() {
    return this._getData("invite_code");
  }

  _getRadarChartData(scoreSummary) {
    let radarChartData = {
      scores: {},
      captions: {},
    };

    scoreSummary.score_per_category.forEach((category) => {
      radarChartData.scores[category.name.toLowerCase()] = category.score / 100;
      radarChartData.captions[category.name.toLowerCase()] = category.name;
    });

    return radarChartData;
  }

  _getState() {
    const scoreSummary = this._getScoreSummary();
    const inviteCode = this._getInviteCode();
    const radarChartData = this._getRadarChartData(scoreSummary);
    return { scoreSummary, inviteCode, radarChartData };
  }

  _mapToStrength = (score) => {
    if (score < 45) return "Low Strength";
    if (score <= 80) return "Moderate Strength";
    if (score <= 100) return "High Strength";
  };

  render() {
    if (!this.state.isStateLoaded) {
      return <div> Loading ... </div>;
    }

    return (
      <Container className="results-page">
        <MainHeader darkLogo={true} showNavLinks={false} />
        <hr />
        <Grid centered verticalAlign="middle" stackable>
          <Grid.Row>
            <Grid.Column
              textAlign="center"
              mobile={16}
              tablet={16}
              computer={10}
            >
              <Header as="h1" size="large" className="mt-3">
                <strong className="results-heading">
                  Thank you for taking the Management Scorecard
                </strong>
              </Header>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column textAlign="left" mobile={16} tablet={8} computer={5}>
              {/* 'result-radar-chart' is used by SSR  */}
              <RadarChartWrapper
                captions={this.state.radarChartData.captions}
                categorizedScores={this.state.radarChartData.scores}
                size={360}
                id="result-radar-chart"
              />
            </Grid.Column>
            <Grid.Column textAlign="left" mobile={16} tablet={8} computer={5}>
              <p className="label">
                <strong className="red-congrats">Congratulations</strong> on
                finishing your Scorecard! The radar chart to the left shows a
                comparison of your performance on the different dimensions of
                management. We have tested you on six different dimensions that
                form the basis of the Manager VALUE-ability Model. Your scores
                on each dimension are listed below.
                <br />
                <br />
                <strong>
                  {" "}
                  Check your email for your personalized Management Scorecard
                  report
                </strong>{" "}
                where your scores are explained and various developmental
                actions for each dimension are provided.
              </p>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row className="w-100">
            <hr className="line" />
          </Grid.Row>

          <Grid.Row className="radial-chart-parent">

            <Grid.Column
              textAlign="center"
              mobile={16}
              tablet={5}
              computer={4}
            >
              <Grid centered stackable>
                <Grid.Row className="mb-2">
                  <h3 className="category-description mb-2">
                    Creating Healthy
                      <br />
                      and Empowering
                      <br /> Workplaces
                    </h3>
                </Grid.Row>
                <Grid.Row className="radial-chart-border">
                  <RadialChart
                    title="Employee"
                    score={
                      this.state.scoreSummary.score_per_category.filter(
                        (obj) => obj.name === "Employee"
                      )[0].score
                    }
                  />
                </Grid.Row>
                <Grid.Row className="radial-chart-border">
                  <RadialChart
                    title="Organization"
                    score={
                      this.state.scoreSummary.score_per_category.filter(
                        (obj) => obj.name === "Organisation"
                      )[0].score
                    }
                  />
                </Grid.Row>
              </Grid>
            </Grid.Column>

            <Grid.Column
              textAlign="center"
              mobile={16}
              tablet={5}
              computer={4}
            >
              <Grid centered stackable>
                <Grid.Row className="mb-2">
                  <h3 className="category-description">
                    Delivering Quality and Fit
                      <br />
                      for Purpose Products
                      <br />
                      and Services
                    </h3>
                </Grid.Row>
                <Grid.Row className="radial-chart-border">
                  <RadialChart
                    title="Customer"
                    score={
                      this.state.scoreSummary.score_per_category.filter(
                        (obj) => obj.name === "Customer"
                      )[0].score
                    }
                  />
                </Grid.Row>
                <Grid.Row className="radial-chart-border">
                  <RadialChart
                    title="Work"
                    score={
                      this.state.scoreSummary.score_per_category.filter(
                        (obj) => obj.name === "Work"
                      )[0].score
                    }
                  />
                </Grid.Row>
              </Grid>
            </Grid.Column>

            <Grid.Column
              textAlign="center"
              mobile={16}
              tablet={5}
              computer={4}
            >
              <Grid centered stackable>
                <Grid.Row className="mb-2" >
                  <h3 className="category-description">
                    Operating Profitable
                      <br /> and Sustainable
                      <br /> Businesses
                    </h3>
                </Grid.Row>
                <Grid.Row>
                  <RadialChart
                    title="Cash"
                    score={
                      this.state.scoreSummary.score_per_category.filter(
                        (obj) => obj.name === "Cash"
                      )[0].score
                    }
                  />
                </Grid.Row>
                <Grid.Row>
                  <RadialChart
                    title="Strategy"
                    score={
                      this.state.scoreSummary.score_per_category.filter(
                        (obj) => obj.name === "Strategy"
                      )[0].score
                    }
                  />
                </Grid.Row>
              </Grid>
            </Grid.Column>

          </Grid.Row>

          <Grid.Row className="w-100">
            <hr className="line" />
          </Grid.Row>

          <Grid.Row>
            <h1 className="share-result-title">
              Find your personalized report on your e-mail
            </h1>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={10}>
              <Segment className="share-segment" padded raised>
                <Grid>
                  <Grid.Row>
                    <Grid.Column mobile={16} tablet={10} computer={10}>
                      <Header as="h3" size="large">
                        Invite your friends to take the scorecard!
                      </Header>
                      <p>PromoCode: {this.state.inviteCode}</p>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={6} computer={6}>
                      <p>Share with your friends</p>
                      <ShareResult invitationCode={this.state.inviteCode} />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
            </Grid.Column>
          </Grid.Row>

        </Grid>
      </Container>
    );
  }
}
export default compose(withRouter)(Result);
