import React from "react";
import "./styles/radialChart.scss";
import { ReactComponent as Radial0 } from "./radialSVGs/0.svg";
import { ReactComponent as Radial17 } from "./radialSVGs/17.svg";
import { ReactComponent as Radial33 } from "./radialSVGs/33.svg";
import { ReactComponent as Radial50 } from "./radialSVGs/50.svg";
import { ReactComponent as Radial67 } from "./radialSVGs/67.svg";
import { ReactComponent as Radial83 } from "./radialSVGs/83.svg";
import { ReactComponent as Radial100 } from "./radialSVGs/100.svg";

export const RadialChart = (props) => {
  let radialSvg;
  switch (Math.floor(props.score / 10)) {
    case 0:
      radialSvg = <Radial0 />;
      break;
    case 1:
      radialSvg = <Radial17 />;
      break;
    case 3:
      radialSvg = <Radial33 />;
      break;
    case 5:
      radialSvg = <Radial50 />;
      break;
    case 6:
      radialSvg = <Radial67 />;
      break;
    case 8:
      radialSvg = <Radial83 />;
      break;
    case 10:
      radialSvg = <Radial100 />;
      break;
    default:
      break;
  }

  return (
    <div className="radial-chart-container">
      <div className="radial-chart">
        {radialSvg}
        <h3 className="radial-chart-category-title">{props.title}</h3>
      </div>
    </div>
  );
};
