export function isLink(s){
    const regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
    return regexp.test(s);
}

export function get(obj, key) {
    return key.split(".").reduce(function (o, x) {
        return (typeof o == "undefined" || o === null) ? o : o[x];
    }, obj);
}


export function isNull(input) {
    return [null, "null", "NULL", "nil"].includes(input)
}

export function getLocalStore(key) {
    const item = localStorage.getItem(key);
    return JSON.parse(item);
}

export function setLocalStore(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
}

export function getBaseUrl() {
    return process.env.REACT_APP_API_BASE_URL;
}

export function getCredintials(){
    return {
        email: process.env.REACT_APP_DEFAULT_API_EMAIL, 
        password: process.env.REACT_APP_DEFAULT_API_PASSWORD
    };
}

export function getRequestHeaders() {
    return { 
        headers: { 
            Authorization: `Bearer ${getLocalStore('client_token')}` 
        } 
    };
}

export function getSiteUrl() {
    return process.env.REACT_APP_SITE_URL;
}

export function getFbPixelId() {
    return process.env.REACT_APP_FB_PIXEL_ID;
}

export function getGTMConfig() {
    return {
        id: process.env.REACT_APP_GTM_ID,
        auth: process.env.REACT_APP_GTM_ENV_AUTH,
        preview: process.env.REACT_APP_GTM_ENV_PREV,
    }
}